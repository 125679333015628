import { useEffect, useState } from "react";
import axios from "axios";
import Result from "./Result";
import { Dna } from "react-loader-spinner";

function Tags() {
  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState("");
  const [newAddedTag, setAddTag] = useState("");
  const [chosenTags, setChosenTags] = useState("");
  const [loading, setLoading] = useState(false);
  const [isOpened, setIsOpened] = useState([]);
  const [sumRecords] = useState(0);
  useEffect(() => {
    if (tags.length < 1) {
      getTags();
    }
    if (sumRecords > 0) {
      // updateTag();
    }
  }, [sumRecords, tags]);

  const getTags = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("apiKey", "u6SIRcX1UQ");
    try {
      const data = await axios.post("/nodeApi/getTags", formData);
      setTags(data.data || []);
    } catch (error) {
      console.error("Error recieving data:", error.message);
      setLoading(false);
      return;
    }
    setLoading(false);
  };

  // const getSumRecords = async () => {
  //   setLoading(true);
  //   const formData = new FormData();
  //   formData.append("apiKey", "u6SIRcX1UQ");
  //   formData.append("tags", currentTag);
  //   console.log("tags", currentTag);
  //   console.log("FormData contents:", formData);
  //   try {
  //     const sumRecords = await axios.post("/nodeApi/getSumRecords", formData, {
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     });
  //     console.log("Server response:", sumRecords.data);
  //     setSum(sumRecords.data);
  //   } catch (error) {
  //     console.error("Error uploading chunk:", error.message);
  //     setLoading(false);
  //     return;
  //   }
  //   setLoading(false);
  //   console.log("getSumRecords finished");
  // };

  // const updateTag = async () => {
  //   console.log("Calling updateTag with sumRecords:", sumRecords);
  //   setLoading(true);
  //   const chunks = 1000;
  //   const totalChunks = Math.ceil(sumRecords / chunks);
  //   for (let i = 0; i < totalChunks; i++) {
  //     const offset = i * chunks;
  //     const formData = new FormData();
  //     formData.append("apiKey", "u6SIRcX1UQ");
  //     formData.append("tag_value", currentTag);
  //     formData.append("new_tag", newTag);
  //     formData.append("offset", offset);
  //     console.log("apiKey:", formData.get("apiKey"));
  //     console.log("tag_value:", formData.get("tag_value"));
  //     console.log("new_tag:", formData.get("new_tag"));
  //     console.log("offset:", formData.get("offset"));
  //     //   const data = await axios.post("/nodeApi/upload", formData);

  //     // const data = await axios.post(
  //     //   "https://adi.otzma.co/api/dataFetching.php?request=updateTag",
  //     //   formData
  //     // );
  //     console.log(
  //       "Sending request to /nodeApi/updateTag with formData:",
  //       formData
  //     );
  //     const data = await axios.post("/nodeApi/updateTag", formData);
  //     console.log(data.data);
  //     console.log(offset);
  //     console.log("Chunks left to process: " + (totalChunks - i - 1));
  //   }
  //   setLoading(false);
  // };

  const updateTag = async () => {
    // console.log("update Tag");
    setLoading(true);
    const formData = new FormData();
    formData.append("apiKey", "u6SIRcX1UQ");
    formData.append("chosenTags", chosenTags);
    formData.append("newTag", newTag);
    // console.log("chosenTags", chosenTags);
    // console.log("new_tag", newTag);
    try {
      const response = await axios.post("/nodeApi/updateTag", formData);
      if (response.data.success) {
        getTags();
        setNewTag("");
        setChosenTags("");
      } else {
        // console.log("Failed to update tag", response.data.message);
      }
    } catch (error) {
      console.error("Error updating tag:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const addTag = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("apiKey", "u6SIRcX1UQ");
    formData.append("value", newAddedTag);
    try {
      const data = await axios.post("/nodeApi/newTag", formData);
      if (data.data.result) {
        getTags();
        setAddTag("");
      }
    } catch (error) {
      console.error("Error adding new tag:", error.message);
      setLoading(false);
      return;
    }
    setLoading(false);
  };

  const isopened = (itemID) => {
    if (isOpened.includes(itemID)) {
      setIsOpened(isOpened.filter((item) => item !== itemID));
    } else {
      setIsOpened((prevArray) => [...prevArray, itemID]);
    }
  };
  return (
    <div>
      <input
        type="text"
        name="tag"
        placeholder="הכנס שם תגית"
        value={newAddedTag}
        onChange={(e) => setAddTag(e.target.value)}
      />
      <button className="addTag" onClick={addTag}>
        הוספת תגית חדשה
      </button>
      <div className="results tags">
        <div className="result-top">
          <div className="column">שם התגית</div>
          <div className="column">עוד מידע</div>
        </div>
        {tags.length < 1 || loading ? (
          <div className="loading">
            <Dna
              visible={true}
              height="80"
              width="80"
              ariaLabel="dna-loading"
              wrapperStyle={{}}
              wrapperClass="dna-wrapper"
            />
          </div>
        ) : (
          tags.map((item, index) => (
            <Result
              item={item}
              isOpened={isopened}
              openedStatus={isOpened}
              key={index}
              currentTag={setChosenTags}
              newTag={setNewTag}
              updateTag={updateTag}
            />
          ))
        )}
      </div>
    </div>
  );
}

export default Tags;
