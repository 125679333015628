import down from "../../images/down.svg";
import opened from "../../images/up.svg";
import { motion, AnimatePresence } from "framer-motion";

// Result component
function Result({
  item,
  isOpened,
  openedStatus,
  currentTag,
  newTag,
  updateTag,
}) {
  const isStatus = (className, secondClass) => {
    return openedStatus.includes(item.id)
      ? className
      : secondClass
      ? secondClass
      : "";
  };
  const setValues = (newValue, currentTagFunc) => {
    // console.log("New value: ", newValue);
    currentTag(currentTagFunc);
    newTag(newValue);
  };

  const updateTagFunc = () => {
    // console.log("Update Tag button clicked!")
    // console.log("currentTag", currentTag);
  updateTag();
  };
  return (
    <div className="result-container">
      <div className={`result-line ${isStatus("active-result")}`}>
        <div className="column">{item.tag}</div>
        <div className="column action-column">
          <div
            className={isStatus("opened", "closed")}
            onClick={() => isOpened(item.id)}
          >
            {openedStatus.includes(item.id) ? (
              <img src={opened} alt="opened-icon" />
            ) : (
              <img src={down} alt="closed-icon" />
            )}
          </div>
        </div>
      </div>
      <AnimatePresence>
        {openedStatus.includes(item.id) && (
          <motion.div
            key="details"
            className={`details_block ${isStatus("show_details")}`}
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
          >
            <div className="result-top">
              <div className="column">קבצים במערכת</div>
              <div className="column">מין</div>
              <input
                type="text"
                name="newtag"
                onChange={(e) => setValues(e.target.value, item.tag)}
                placeholder="שינוי שם התגית"
              />
              <button onClick={updateTagFunc}>שמור</button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default Result;
