import { useState, useEffect } from "react";
import { Dna } from "react-loader-spinner";
import Result from "./Result";
import ResultFirst from "./resultFirst";
import axios from "axios";
import download from "../../images/download.png";

function FillData() {
  const [file, setFile] = useState(null);
  const [headerValues, setHeaderValues] = useState([]);
  const [rowData, setRow] = useState([]);
  const [uploaded, setUploaded] = useState(0);
  const [noResults, setNoResults] = useState("");
  const [firstData, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState([]);
  const [crossVal, setCross] = useState("");
  const [csvName, setName] = useState("");
  const [csvURL, setURL] = useState("");

  useEffect(() => {
    setName("csv" + generateRandomString(5));
  }, [fields]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const saveFields = (index) => {
    if (fields.includes(index)) {
      setFields(fields.filter((item) => item !== index));
    } else {
      setFields((prevArray) => [...prevArray, index]);
    }
  };
  const getFirstData = () => {
    setLoading(true);
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      const content = e.target.result;
      const lines = content.split("\n");
      let rows = [];
      if (lines.length > 0) {
        for (let i = 0; i <= 7 && i < lines.length; i++) {
          rows.push(lines[i].split("\r")[0]);
        }
        setRow(rows);
        setLoading(false);
        getData(rows);
      }
    };
    fileReader.readAsText(file);
  };
  const getData = async (rows) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("data", JSON.stringify(rows));
      formData.append("cross", crossVal);
      formData.append("apiKey", "u6SIRcX1UQ");

      const response = await axios.post("nodeApi/getInitialData", formData,{
        headers: {
          "Content-Type": "application/json",
        },
      });
      // const response = await axios.post('https://adi.otzma.co/api/dataFetching.php?request=dataFill', formData);

      // for (const pair of formData.entries()) {
      //   console.log(`${pair[0]}: ${pair[1]}`);
      // }

      if (response.data.data.length > 0) {
        setData(response.data.data);
        setHeaderValues(Object.keys(response.data.data[0]));
      } else {
        setNoResults(
          "אין תוצאות ראשוניות לקובץ , נסה להפעיל את ההורדת מידע המלא"
        );
      }
    } catch (error) {
      console.error("Error getting data:", error.message);
      setLoading(false);
      return;
    }

    setLoading(false);
  };

  const generateRandomString = (length) => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }

    return result;
  };

  const createCsvFile = async () => {
    if (!file) {
      console.error("No file selected");
      return;
    }

    setLoading(true);

    const ROWS_PER_CHUNK = 1000; // Number of rows per chunk
    let uploadedChunks = 0;
    const fileReader = new FileReader();

    fileReader.onload = async (event) => {
      let content = event.target.result.split("\n");
      // console.log("File content read:", content);
      const totalRecords = content.length;
      const totalChunks = Math.ceil(totalRecords / ROWS_PER_CHUNK);
      // console.log(totalRecords);

      // Send each chunk to the PHP backend
      for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
        const start = chunkIndex * ROWS_PER_CHUNK;
        const end = Math.min(start + ROWS_PER_CHUNK, totalRecords);
        const chunk = content.slice(start, end).join("\n");

        const formData = new FormData();
        formData.append("csvFile", new Blob([chunk], { type: "text/plain" }));
        formData.append("cross", crossVal);
        formData.append("apiKey", "u6SIRcX1UQ");
        formData.append("fileName", csvName);
        formData.append("fields", JSON.stringify(fields));
        // console.log("Sending fields:", JSON.stringify(fields));
        // console.log("Sending chunk:", chunk);
        try {
          // Send the chunk to the PHP backend
          const response = await axios.post("/nodeApi/fillData", formData);
          uploadedChunks = uploadedChunks + ROWS_PER_CHUNK;
          const percentage = Math.min(
            (uploadedChunks / totalRecords) * 100,
            100
          );
          if (percentage === 100) {
            setURL(csvName);
            setURL(response.data.fileURL);
          }
          setUploaded(percentage.toFixed(2));
        } catch (error) {
          console.error("Error uploading chunk:", error.message);
          setLoading(false);
          return;
        }
      }

      // console.log("File upload successful");
      setLoading(false);
    };

    fileReader.readAsText(file, "UTF-8");
  };

  return (
    <div className="uploadFiles">
      <div className="top_section">
        <div className="uploadBox">
          <div className="uploadField">
            <input
              type="file"
              id="fileInput"
              className="custom-file-input"
              onChange={handleFileChange}
            />
            <label htmlFor="fileInput" className="custom-file-label">
              בחר קובץ
            </label>
            <span className="filename">{file ? file.name : ""}</span>
            <button onClick={getFirstData}>העלאת קובץ</button>
          </div>
          {loading ? (
            <Dna
              visible={true}
              height="80"
              width="80"
              ariaLabel="dna-loading"
              wrapperStyle={{}}
              wrapperClass="dna-wrapper"
            />
          ) : (
            ""
          )}
          <div className="uploadedBox">
            <div className="uploadedAmount" style={{ width: `${uploaded}%` }}>
              {uploaded}%
            </div>
          </div>
        </div>
        <div className="cross">
          <select
            name="cross"
            // onChange={(e) => setCross(e.target.value)}
            onChange={(e)=>{
              setCross(e.target.value)
            }}
            value={crossVal}
          >
             <option value="">בחר אפשרות</option>
            <option value="phone">חיפוש לפי מספרי טלפון</option>
            <option value="idnumber">חיפוש לפי ת"ז</option>
            <option value="fbID">חיפוש לפי פייסבוק איידי</option>
          </select>
        </div>
      </div>
      {rowData.length > 0 && !loading && !csvURL ? (
        <div className="result-container firstData">
          <Result rowData={rowData} />
        </div>
      ) : (
        ""
      )}
      {firstData.length > 0 ? (
        <div className="result-container firstData data-fill">
          <div className="result-line header-line">
            {headerValues.map((value, index) => (
              <div className="column" key={index}>
                {value !== "fbID" ? (
                  <input
                    type="checkbox"
                    name="field"
                    value={value}
                    onClick={(e) => saveFields(value)}
                  />
                ) : (
                  ""
                )}
                {value}
              </div>
            ))}
          </div>
          <div className="seperator"></div>
          <ResultFirst rowData={firstData} keys={headerValues} />
        </div>
      ) : (
        <div className="noresults">{noResults}</div>
      )}
      {rowData.length > 0 ? (
        <div className="bottom">
          {!csvURL ? (
            <button className="approveUpload" onClick={createCsvFile}>
              סיום והורדת המידע
            </button>
          ) : (
            <a href={`nodeApi/downloadFillData`} download>
              הורדת CSV
              <img src={download} alt="הורדת CSV" />
            </a>
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default FillData;
