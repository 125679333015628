import Result from "./Result";
import Filters from "./Filters";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Dna } from "react-loader-spinner";
import download from "../../images/download.png";

function GetData() {
  const [content, setContent] = useState([]);
  const [filters, setFilters] = useState([]);
  const [pages, setPages] = useState(0);
  const [search, setSearchKey] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [csvURL, setCSVURL] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingCSV, setLoadingCSV] = useState(false);
  const [error, setError] = useState("");
  const [searchClicked, setSearchClicked] = useState(false);
  const [isOpened, setIsOpened] = useState([]);
  const [requestId, setRequestId] = useState("");


  const fetchContent = async (searchKey, page) => {
    try {
      setError("");
      setLoading(true);
      const formData = new FormData();
      formData.append("page", page);
      formData.append("searchKey", searchKey);
      formData.append("filters", JSON.stringify(filters));
      formData.append("apiKey", "u6SIRcX1UQ");


      const data = await axios.post("/nodeApi/search", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const { requestId, data: searchData } = data.data;
      if (data.data.data.length > 0) {
        if (data.data.count > 0) {
          setPages(data.data.count);
        }
        setContent(data.data.data);
        setRequestId(requestId);
      } else {
        if (page === 1) {
          setContent("");
          setError("אין תוצאות חיפוש למילת מפתח זו");
        }
      }
      setLoading(false);
    } catch (error) {
      console.error("שגיאה בשליפת נתונים:", error);
    }
  };

  const searchVal = (value) => {
    setSearchKey(value);
  };

  const searchFunc = () => {
    setSearchClicked(true);
    setCSVURL("");
  };

  // const isopened = (itemID) => {

  //   if (isOpened.includes(itemID)) {
  //     setIsOpened(isOpened.filter((item) => item !== itemID));
  //   } else {
  //     setIsOpened((prevArray) => [...prevArray, itemID]);
  //   }
  // };

  const exportCSV = async () => {
    try {
      setError("");
      setLoadingCSV(true);
      const formData = new FormData();
      formData.append("searchKey", search);
      formData.append("filters", JSON.stringify(filters));
      formData.append("apiKey", "u6SIRcX1UQ");
      formData.append("requestId", requestId);
      const data = await axios.post("/nodeApi/exportcsv",formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const filePath = data.data.filePath;
      const fileUrl=`/nodeApi/download/${filePath}`
      if (fileUrl) {
        setCSVURL(fileUrl);
      } else {
        setError(data.data.error);
      }
      setLoadingCSV(false);
    } catch (error) {
      console.log("filePath",setCSVURL)
      console.error("שגיאת ביצירת הקובץ:", error);

    }
  };

  const getNextPage = (typePage) => {
    if (typePage === "prev") {
      setCurrentPage(currentPage - 1);
    } else {
      setCurrentPage(currentPage + 1);
    }
    setSearchClicked(true);
  };

  const setValues = (value) => {
    setFilters(value);
  };



  useEffect(() => {
    if (searchClicked) {
      fetchContent(search, currentPage);
      setSearchClicked(false);
    }
  }, [search, currentPage, searchClicked,fetchContent]);

  return (
    <div>
      <Filters
        doSearch={searchFunc}
        searchKeyVal={searchVal}
        filterValues={setValues}
      />
      {loading ? (
        <Dna
          visible={true}
          height="80"
          width="80"
          ariaLabel="dna-loading"
          wrapperStyle={{}}
          wrapperClass="dna-wrapper"
        />
      ) : content.length > 0 ? (
        <>
          <div className="results-text">
            תוצאות חיפוש עבור :&nbsp;
            {search ? `מילת מפתח:${search} | ` : ""}
            {filters[0].tags ? `תגיות - ${filters[0].tags} |  ` : ""}
            {filters[0].fileSource
              ? `קובץ מקור - ${filters[0].fileSource} | `
              : ""}
            {filters[0].city ? `עיר - ${filters[0].city}` : ""}
            {` | מספר תוצאות: ${pages}`}
            <div className="buttonCSV" onClick={exportCSV}>
              {csvURL ? (
                <a href={csvURL} download>
                  הורדת CSV
                  <img src={download} alt="הורדת CSV" />
                </a>
              ) : (
                <span>
                  {loadingCSV ? <span>יוצר קובץ..</span> : "יצירת קובץ CSV"}
                </span>
              )}
            </div>
          </div>
          <div className="results">
            <div className="result-top">
              <div className="column">שם פרטי</div>
              <div className="column">שם משפחה</div>
              <div className="column">טלפון</div>
              <div className="column">קובץ מקור</div>
              {/* <div className="column actions">פעולות</div> */}
              <div className="column"> </div>

            </div>
            {content.map((item,index) => (
              <Result
                item={item}
                // isOpened={isopened}
                openedStatus={isOpened}
                
                key={item.id || index}
              />
            ))}
          </div>
          <button
            onClick={() => getNextPage("prev")}
            disabled={currentPage === 1}
          >
            עמוד הקודם
          </button>
          <button onClick={() => getNextPage("next")}>עמוד הבא</button>
        </>
      ) : (
        <div className="error">{error}</div>
      )}
    </div>
  );
}

export default GetData;
