function Result({ rowData }) {
    return (
        rowData.map((data, index) => (
            <div className="result-line" key={index}>
                {data.map((val, colIndex) => (
                    <div key={colIndex} className="column">
                        {val}
                    </div>
                ))}
            </div>
        ))
    );
}

export default Result;