import { Link, useLocation } from 'react-router-dom';
import download from "./images/download.png";
import upload from "./images/upload.png";
import fill from "./images/datafill.png";
import tags from "./images/tags.svg";


function Menu() {

    const location = useLocation();

    const isActive = (path) => {
        return location.pathname === path;
    };

    return (
        <div className="menu">
            <ul>
                <li className={isActive('/') ? 'active' : ''}><div className="icon"><img src={download} alt="חיפוש מידע" /></div><Link to={`/`}><span>חיפוש והורדת מידע</span></Link></li>
                <li className={isActive('/upload') ? 'active' : ''}><div className="icon"><img src={upload} alt="העלאת מידע" /></div><Link to={`/upload`}><span>העלאת מידע</span></Link></li>
                <li className={isActive('/datafill') ? 'active' : ''}><div className="icon"><img src={fill} alt="מילוי מידע" /></div><Link to={`/datafill`}><span>מילוי מידע</span></Link></li>
                <li className={isActive('/tags') ? 'active' : ''}><div className="icon"><img src={tags} alt="תגיות" /></div><Link to={`/tags`}><span>ניהול תגיות</span></Link></li>
            </ul>
        </div>
    );
}

export default Menu;