import axios from "axios";
import { useState, useEffect } from "react";
import exit from "../../images/close.svg";

function Popup({ id, saveFields, saveNew, closePopup }) {
    const [sqlFields, setFields] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [selectedField, setSelected] = useState('phone');
    const [newField, setNew] = useState([]);

    const getSqlFields = async () => {
        setLoading(true);
        const formData = new FormData();
        formData.append('apiKey', 'u6SIRcX1UQ');
        const response=await axios.post("/nodeApi/elasticFields", formData);
        setFields(response.data.fields);
        setLoading(false);
    };

    const closePopupFunc = () => {
        closePopup(id);
    };

    useEffect(() => {
        getSqlFields();
    }, []);

    const saveField = () => {
        if (newField.length > 0) {
            saveFields(id, newField);
            saveNew(id, newField);
        } else {
            saveFields(id, selectedField);
        }
    };
    return (
        <div className="popup">
            <div className="close" onClick={closePopupFunc}><img src={exit} alt="exit-popup" /></div>
            {isLoading === false ?
                <select onChange={(event) => setSelected(event.target.value)} value={selectedField}>
                    {sqlFields.map(field => {
                        return <option value={field} key={field}>{field}</option>
                    })}
                    <option value="new">שדה חדשה</option>
                </select> : 'טוען שדות מסד נתונים..'}
            {selectedField === "new" ? <input type="text" name="newfield" placeholder="שדה חדש" value={newField} onChange={(event) => setNew(event.target.value)} /> : ''}
            <button onClick={saveField}>שמור</button>
        </div>
    );
}
export default Popup;