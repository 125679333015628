import axios from "axios";
import {  useState } from "react";

import { useCookies } from "react-cookie";
import { Dna } from "react-loader-spinner";

function Login({ lastProp }) {
  const [emailCode, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [inputCode, setInput] = useState("");
  const [error, setError] = useState("");
  const [cookies, setCookie] = useCookies(["username"]);

  // useEffect(() => {
  //     setCookie('username', "sm", { path: '/' });
  // }, [setCookie])

  // useEffect(() => {
  //   // setCookie('username', username, { path: '/' });
  // }, [setCookie, username]);

  const startLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    if (!username || !password) {
      setError("נא מלא את שם משתמש וסיסמא");
      setLoading(false);
      return;
    }
    const formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);
    formData.append("apiKey", "u6SIRcX1UQ");
    try {
      const data = await axios.post("nodeApi/login", formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      // const data = await axios.post('https://adi.otzma.co/api/dataFetching.php?request=login', formData);

      if (data.data.code) {
        setCode(data.data.code);
        // setCookie('username',username,{path:'/'})
        await lastLogged();
      } else {
        setError(data.data.error);
      }
    } catch (error) {
      if (error.data) {
        setError(error.data.error || "שגיאה בהתחברות");
      }
    } finally {
      setLoading(false);
    }
  };

  const lastLogged = async () => {
    const formData = new FormData();
    formData.append("username", username);
    formData.append("apiKey", "u6SIRcX1UQ");
    await axios.post("nodeApi/lastLogged", formData, {
      headers: { "Content-Type": "application/json" },
    });

    // await axios.post('https://adi.otzma.co/api/dataFetching.php?request=lastLogged', formData);
  };

  const setCookieFunc = () => {
    setCookie("username", username, { path: "/" });
    // lastLogged()
  };

  const doLogin = async () => {
    if (inputCode !== emailCode) {
      setError("הקוד שהכנסת שגוי..");
    } else {
      // await lastLogged();
      setCookieFunc();
    }
  };

  return (
    <div className="login-page">
      <h1>התחברות למערכת</h1>
      <form method="post">
        <div className="login-form">
          {!loading ? (
            !emailCode ? (
              <>
                <input
                  type="text"
                  name="username"
                  autoComplete="true"
                  value={username}
                  placeholder="שם משתמש"
                  onChange={(e) => setUsername(e.target.value)}
                />
                <input
                  type="password"
                  name="password"
                  value={password}
                  placeholder="סיסמא"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button onClick={startLogin}>התחברות</button>
              </>
            ) : (
              <div className="email-code">
                <input
                  type="email"
                  name="email"
                  placeholder="קוד שהתקבל בדואר אלקטרוני"
                  onChange={(e) => setInput(e.target.value)}
                />
                <button onClick={doLogin}>התחבר</button>
              </div>
            )
          ) : (
            <Dna
              visible={true}
              height="80"
              width="80"
              ariaLabel="dna-loading"
              wrapperStyle={{}}
              wrapperClass="dna-wrapper"
            />
          )}
          <div className="error">{error}</div>
        </div>
      </form>
    </div>
  );
}

export default Login;
