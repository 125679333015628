import React from 'react';
import ContentList from './ContentList';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from './Header';
import "./style.css";

function App() {
  return (
    <div className="App">
      <Header />
      <Router>
        <ContentList />
      </Router>
    </div>
  );
}

export default App;