import filter from "../../images/Filter.png";
import Filter from "./Filter";
import { useState, useEffect } from "react";
import { motion, AnimatePresence } from 'framer-motion';

function Filters({ doSearch, searchKeyVal, filterValues }) {
    const [filters, setFilters] = useState([]);
    const [filterVals, setFilterVals] = useState([{ city: '', tag: '', fileSource: '' }]);
    const [search, setSearchVal] = useState('');
    const [isOpened, setIsOpened] = useState(false);
    const addFilter = (value) => {
        (!filters.includes(value) ?
            setFilters(prevArray => [...prevArray, value]) :
            setFilters(filters.filter(item => item !== value))
        );
    };

    useEffect(() => {
        filterValues(filterVals);
    }, [filterVals, filterValues]);

    const setFiltersFunc = (value) => {
        setFilterVals(value);
    };

    const triggerSearch = () => {
        searchKeyVal(search);
        setIsOpened(false);
        doSearch();
    };

    const openFilters = () => {
        setIsOpened(!isOpened);
    };

    return (
        <div className="filters">
            <div className="filter_container">
                <button onClick={openFilters} ><img src={filter} alt="filter-icon" />סנן</button>
                <AnimatePresence>
                    {isOpened ? <motion.div className="filters_dropdown"
                        initial={{ opacity: 0, }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        <Filter name="תגיות" filters={filters} filterValues={filterVals} isChecked="tag" triggerAdd={addFilter} setValue={setFiltersFunc} />
                        <Filter name="קובץ מקור" filters={filters} filterValues={filterVals} isChecked="fileSource" triggerAdd={addFilter} setValue={setFiltersFunc} />
                        <Filter name="עיר" filters={filters} filterValues={filterVals} isChecked="city" triggerAdd={addFilter} setValue={setFiltersFunc} />
                    </motion.div> : ''}
                </AnimatePresence>
            </div>
            <input type="text" onChange={(event) => setSearchVal(event.target.value)} value={search} className="freefield" placeholder="חיפוש(שם,שם משפחה או תעודת זהות)" />
            <button onClick={triggerSearch}>חיפוש</button>
        </div>
    );
}

export default Filters;