import GetData from "././pages/getData/getData";
import UploadData from "././pages/uploadData/uploadData";
import FillData from "././pages/dataFill/fillData";
import Tags from "././pages/tags/tags";
import Menu from "./Menu";
import { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./users/login";
import { useCookies } from "react-cookie";
import exit from "./images/exit.svg";
import user from "./images/user.png";
import axios from "axios";

function ContentList() {
  const [cookies, removeCookie] = useCookies(["username"]);
  const [lastVal, setLast] = useState("");
  useEffect(() => {
    async function getLogged() {
      if (!cookies.username) {
        console.warn("No user logged in");
        return;
      }
      const formData = new FormData();
      formData.append("username", cookies.username);
      formData.append("apiKey", "u6SIRcX1UQ");
      try {
        const data = await axios.post("nodeApi/getLoggedUser", formData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        // const data = await axios.post(
        //   "https://adi.otzma.co/api/dataFetching.php?request=getLast",
        //   formData
        // );
        setLast(data.data.data.lastLogged);
      } catch (error) {
        console.log("Error fetching logged user", error);
      }
    }
      getLogged();
  }, [cookies.username]);

  const getTime = (date) => {
    if (typeof date !== "string") {
      console.error("date is not a string");
      return { time: "", date: "" };
    }
    date = date.split(" ");
    return { time: date[1], date: date[0] };
  };

const logout=()=>{
  removeCookie("username","")
}
  return (
    <div className="container main-content">
      {cookies.username ? (
        <div className="sidebar">
          <Menu />
          {cookies.username && (
            <div className="loginDetails">
              <div className="username">
                <div className="user-image">
                  <img src={user} alt="user-img" />
                </div>
                <span>
                  {typeof cookies.username === "string"
                    ? cookies.username
                    : "No user logged in"}
                </span>
                {/* <span>{cookies.username }</span> */}
              </div>
              <span className="last-title">תאריך התחברות אחרון:</span>
              <span>{getTime(lastVal).date}</span>
              <span>{getTime(lastVal).time}</span>
              <button onClick={logout}>
                יציאה <img src={exit} alt="exit-user" />
              </button>
            </div>
          )}
        </div>
      ) : (
        ""
      )}
      <div className={`content ${!cookies.username ? "login_screen" : ""}`}>
        {cookies.username ? (
          <Routes>
            <Route path="/" element={<GetData />} />
            <Route path="/upload" element={<UploadData />} />
            <Route path="/datafill" element={<FillData />} />
            <Route path="/tags" element={<Tags />} />
          </Routes>
        ) : (
          <Login />
        )}
      </div>
    </div>
  );
}

export default ContentList;
