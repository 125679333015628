import React from "react";
import down from "../../images/down.svg";
import opened from "../../images/up.svg";
import { motion, AnimatePresence } from "framer-motion";

// Result component
function Result({ item, isOpened, openedStatus }) {
  const data = item._source || item;
  // console.log("data",data)
  //   const tagsDisolve = (tags) => {
  //     if (typeof tags !== "string") {
  //       return <div>No Tags Available</div>;
  //     }
  //     return tags.split(",").map((tag) => {
  //       return (
  //         <div className="tagContainer" key={tag}>
  //           {tag}
  //         </div>
  //       );
  //     });
  //   };
  const tagsDisolve = (tags) => {
    if (Array.isArray(tags)) {
      return tags.map((tag) => (
        <div className="tagContainer" key={tag}>
          {tag}
        </div>
      ));
    } else if (typeof tags === "string") {
      return tags.split(",").map((tag) => (
        <div className="tagContainer" key={tag.trim()}>
          {tag.trim()}
        </div>
      ));
    }
    return <div>No Tags Available</div>;
  };
  const isStatus = (className, secondClass) => {
    return openedStatus.includes(data.id)
      ? className
      : secondClass
      ? secondClass
      : "";
  };

  return (
    <div className="result-container">
      <div className={`result-line ${isStatus("active-result")}`}>
        <div className="column">{data.firstname}</div>
        <div className="column">{data.lastname}</div>
        <div className="column">{data.phone}</div>
        <div className="column">{data.fileSource}</div>
        <div className="column action-column">
          {/* <div
            className={isStatus("opened", "closed")}
            onClick={() => isOpened(data.id)}
          >
            {openedStatus.includes(data.id) ? (
              <img src={opened} alt="opened-icon" />
            ) : (
              <img src={down} alt="closed-icon" />
            )}
          </div> */}
        </div>
      </div>
      <AnimatePresence>
        {openedStatus.includes(data.id) && (
          <motion.div
            key="details"
            className={`details_block ${isStatus("show_details")}`}
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
          >
            <div className="result-top">
              <div className="column">ת"ז</div>
              <div className="column">מין</div>
              <div className="column">עיר</div>
              <div className="column">רחוב</div>
              <div className="column">שם האב</div>
              <div className="column">תגיות</div>
            </div>
            <div className="more_details result-line">
              <div className="column">{data.idnumber}</div>
              <div className="column">{data.gender}</div>
              <div className="column">{data.city}</div>
              <div className="column">
                {data.street} - דירה:{data.apartment}
              </div>
              <div className="column">{data.fathername}</div>
              <div className="column">{tagsDisolve(data.tags)}</div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default Result;
