import Result from "./Result";
import Filters from "./Filters";
import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Dna } from "react-loader-spinner";
import download from "../../images/download.png";

function GetData() {
  const [content, setContent] = useState([]);
  const [filters, setFilters] = useState([]);
  const [pages, setPages] = useState(0);
  const [search, setSearchKey] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [csvURL, setCSVURL] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingCSV, setLoadingCSV] = useState(false);
  const [error, setError] = useState("");
  const [searchClicked, setSearchClicked] = useState(false);
  const [isOpened] = useState([]);
  const [requestId, setRequestId] = useState("");

  const fetchContent = useCallback(
    async (searchKey, page) => {
      try {
        setError("");
        setLoading(true);
        const formData = new FormData();
        formData.append("page", page);
        formData.append("searchKey", searchKey);
        formData.append("filters", JSON.stringify(filters));
        formData.append("apiKey", "u6SIRcX1UQ");
        const data = await axios.post("/nodeApi/search", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        const { requestId, data: searchData } = data.data;
        console.log("searchData" + searchData.data);
        if (data.data.data.length > 0) {
          if (data.data.count > 0) {
            setPages(data.data.count);
          }
          setContent(data.data.data);
          setRequestId(requestId);
        } else {
          if (page === 0) {
            setContent("");
            setError("אין תוצאות חיפוש למילת מפתח זו");
          }
        }
        setLoading(false);
      } catch (error) {
        console.error("שגיאה בשליפת נתונים:", error);
      }
    },
    [filters]
  );
  const searchVal = (value) => {
    setSearchKey(value);
  };

  const searchFunc = () => {
    setSearchClicked(true);
    setCSVURL("");
  };
  const exportCSV = async () => {
    try {
      if (csvURL) {
        console.log("הקובץ כבר מוכן, אין צורך ליצור מחדש.");
        return;
      }
      setError("");
      setLoadingCSV(true);
      const queryParams = new URLSearchParams({
        searchKey: search || "",
        apiKey: "u6SIRcX1UQ",
        requestId: requestId || "",
      });
      // filters.forEach(f => queryParams.append(f.key,f.value))
      filters.forEach((filter) => {
        Object.entries(filter).forEach(([key, value]) => {
          if (value) {
            queryParams.append(key, value);
          }
        });
      });
      const url = `/nodeApi/exportCsv?${queryParams.toString()}`;
      console.log("URL שנשלח:", url);
      const data = await window.open(url);
      if (data) {
      } else {
        setError(data.data.error);
      }
    } catch (error) {
      console.error("שגיאת ביצירת הקובץ:", error);
    }
  };

  const getNextPage = (typePage) => {
    if (typePage === "prev") {
      setCurrentPage(currentPage - 1);
    } else if (typePage === "next") {
      setCurrentPage(currentPage + 1);
    }
    setSearchClicked(true);
  };

  const setValues = (value) => {
    setFilters(value);
  };

  useEffect(() => {
    if (searchClicked) {
      setLoadingCSV(false);
      fetchContent(search, currentPage);
      setSearchClicked(false);
    }
  }, [search, currentPage, searchClicked, fetchContent]);

  return (
    <div>
      <Filters
        doSearch={searchFunc}
        searchKeyVal={searchVal}
        filterValues={setValues}
      />
      {loading ? (
        <Dna
          visible={true}
          height="80"
          width="80"
          ariaLabel="dna-loading"
          wrapperStyle={{}}
          wrapperClass="dna-wrapper"
        />
      ) : content.length > 0 ? (
        <>
          <div className="results-text">
            תוצאות חיפוש עבור :&nbsp;
            {search ? `מילת מפתח:${search} | ` : ""}
            {filters[0].tags ? `תגיות - ${filters[0].tags} |  ` : ""}
            {filters[0].fileSource
              ? `קובץ מקור - ${filters[0].fileSource} | `
              : ""}
            {filters[0].city ? `עיר - ${filters[0].city}` : ""}
            {` | מספר תוצאות: ${pages}`}
            <div className="buttonCSV" onClick={!csvURL ? exportCSV : null}>
              {csvURL ? (
                <a href={csvURL} download>
                  הורדת CSV
                  <img src={download} alt="הורדת CSV" />
                </a>
              ) : (
                <span>
                  {loadingCSV ? <span>הקובץ מוכן</span> : "יצירת קובץ CSV"}
                </span>
              )}
            </div>
          </div>
          <div className="results">
            <div className="result-top">
              <div className="column">שם פרטי</div>
              <div className="column">שם משפחה</div>
              <div className="column">טלפון</div>
              <div className="column">קובץ מקור</div>
              <div className="column"> </div>
            </div>
            {content.map((item, index) => (
              <Result
                item={item}
                openedStatus={isOpened}
                key={item.id || index}
              />
            ))}
          </div>
          <button
            onClick={() => getNextPage("prev")}
            disabled={currentPage === 0}
          >
            עמוד הקודם
          </button>
          <button
            onClick={() => getNextPage("next")}
            disabled={currentPage > Math.ceil(pages / 7)}
          >
            עמוד הבא
          </button>
        </>
      ) : (
        <div className="error">{error}</div>
      )}
    </div>
  );
}

export default GetData;
